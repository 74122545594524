import React, { useEffect, useState } from "react";
import ChatTextBox from "./avatar"

const ChatBot = () =>{
    const [accessToken, setAccessToken] = useState("");
    const [userEmail, setUserEmail] = useState("");

    function getSessionStorageItemBySubstring() {
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);
            if (key && key.includes('accesstoken')) {
                return sessionStorage.getItem(key);
            }
        }
        return null;
    }

    function decodeJWT(jwt) {
        const [header, payload, signature] = jwt.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        return decodedPayload;
    }

    useEffect(function () {
        const matchingItem = getSessionStorageItemBySubstring();
        if (matchingItem) {
            setAccessToken(JSON.parse(matchingItem).secret);
            setUserEmail(decodeJWT(JSON.parse(matchingItem).secret).unique_name);
        }
        
    }, [])

    return(
        <>
        {/* {!(userEmail==="") && ( */}
            <ChatTextBox userEmail={userEmail} accessToken={accessToken}/>
        {/* )} */}
        </>
    )
}

export default ChatBot;