import axios from "axios";
import React, { useState } from "react";
import { baseurl } from "../config";
import { toast } from "react-toastify";
function useApi() {
  const fecthApi = async ({ api, method, data, headers }) => {
    return await axios({
      url: `${baseurl}/${api}`,
      method: method,
      data: data,
      headers: headers,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast(error.response?.data?.message || error?.message);
        console.error(error);
      });
  };

  return {
    fecthApi,
  };
}

export default useApi;
