import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useApi from "../useApi";
import { toast } from "react-toastify";
import { MediaUrl } from "../../config";

export const UploadLogo = createContext();

export const LogoProvider = ({ children }) => {
  const [logo, setLogo] = useState(null);
  const [text, setText] = useState("");
  const [File, setFile] = useState(null);
  const { fecthApi } = useApi();


  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const getLogo = async () => {
    try {
      const response = await fecthApi({
        api: "headtitle/logoTitle",
        method: "GET",
      });

      if (response.status === true) {
        setLogo(`${MediaUrl}${response.data.logoUrl}`);
        setText(response.data.title);
      }
    } catch (error) {
      console.log("getLogo ~ error:", error);
      toast.error(error?.message);
    }
  };

  const uploadLogo = async () => {
    if (File) {
      try {
        const formData = new FormData();
        formData.append("logoUrl", File);
        formData.append("title", text);

        const response = await fecthApi({
          api: "headtitle/logoTitle",
          method: "POST",
          data: formData,
        });

        if (response.status === true) {
          const logoData = await response.data.logoUrl;
          setLogo(logoData);
          setText(text);
          toast.success(response.message);
          getLogo();
        } else {
          console.error("Failed to upload logo:", response.message);
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error uploading logo:", error.message);
        toast.error(error?.message);
      }
    }
  };

  const updateLogo = async () => {
    try {
      const response = await fecthApi({
        api: "headtitle/logoTitle",
        method: "PUT",
        data: { logoUrl: logo, title: text },
      });
      console.log("updateLogo ~ response:", response);

      if (response.status === true) {
        const logoData = await response.data.logoUrl;
        setLogo(logoData);
        setText(response.data.title);
        toast.success(response.message);
        getLogo();
      } else {
        console.error("Failed to update logo:", response.message);
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }
  };

  const updateText = (e) => {
    setText(e.target.value);
  };

  const logoValues = {
    logo,
    uploadLogo,
    text,
    File,
    updateText,
    handleFileInputChange,
    updateLogo,
    getLogo,
  };

  return (
    <UploadLogo.Provider value={logoValues}>{children}</UploadLogo.Provider>
  );
};
