import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MainHeader from "./components/MainHeader";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Modal from "react-modal";

import UploadFile from "./pages/UploadFile";
import Chats from "./pages/Chats";
import SpeechText from "./pages/SpeechText";
import BotAi from "./pages/BotAi";
import Chatbot from "./pages/chatbot_new/user";
import Admin from "./pages/Admin";
import CrudFaq from "./components/CrudFaq";
import FileUpload from "./components/FileUpload";
import CusomizeApp from "./components/CusomizeApp";
import HomeLayout from "./components/HomeLayout";
import AccessToken from "./components/AccessToken";
import Avtars from "./components/Avatars";
import TalkingAvtras from "./components/TalkinAvtar";
import ChatingBot from "./pages/ChatingBot";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";
import { loginRequest } from "./auth-config";
import MicrosoftLoginButton from './pages/sso'
import LoginPage from "./pages/chatbot_new/loginPage";
import SignupPage from "./pages/chatbot_new/signupPage";
import { BrowserRouter, useHistory } from "react-router-dom";

const WrappedView = () => {

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleRedirect = () => {
      setLoading(true);
      instance.loginRedirect({
        ...loginRequest,
        prompt: 'create',
      })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    };

    if (!activeAccount && !loading) {
      handleRedirect();
    }
  }, [activeAccount, instance, loading]);


  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <AppInstance />
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {!activeAccount && !loading && <MicrosoftLoginButton onLogin={() => setLoading(true)} />}
      </UnauthenticatedTemplate>
    </div>
  );
};

const App = ({ instance }) => {
  return (
    // <MsalProvider instance={instance}>
    //   <WrappedView />
    // </MsalProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/:id" element={<Chatbot />} />
      </Routes>
    </BrowserRouter>
  );
};


function AppInstance() {
  Modal.setAppElement(document.getElementById("root"));

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

function AppContent() {
  return (
    <div id="app">
      {/* <BotAi/>     */}
      <Chatbot />
      {/* <LoginPage />
      <SignupPage/> */}

    </div>
  );
}

// function AppContent() {
//   // const location = useLocation();
//   // const excludedRoutes = [
//   //   "/login",
//   //   "/register",
//   //   "/admin",
//   //   "/upload-file",
//   //   "/chat",
//   //   "/speech-to-text",
//   //   "/chatbot",
//   //   "/admin/faqs/:page",
//   //   "/admin/upload-file/:page",
//   //   "/admin/customize-app",
//   //   "/admin/chatbot",
//   // ];
//   // let userData = JSON.parse(localStorage.getItem("userdata"));

//   // const id = useParams();

//   return (
//     <div id="app">  
//       <BotAi/>    
//       {/* <Routes>
//         <Route
//           path="/"
//           element={<BotAi/>}
//         /> */}
//         {/* <Route
//           path="/login"
//           element={userData ? <Navigate to="/" /> : <Login />}
//         />
//         <Route path="/register" element={<Register />} />
//         {userData && (
//           <Route path="/admin" element={<Admin customclass={true} />}>
//             <Route path="faqs" element={<Navigate to="1" />} />
//             <Route path="faqs/:page" element={<CrudFaq />} />
//             <Route path="upload-file" element={<Navigate to="1" />} />
//             <Route path="upload-file/:page" element={<FileUpload />} />
//             <Route path="customize-app" element={<CusomizeApp />} />
//             <Route path="chatbot" element={<BotAi customclass={true} />} />
//             <Route path="access-token" element={<Navigate to="1" />} />
//             <Route path="access-token/:page" element={<AccessToken />} />
//             <Route path="talking-avtar" element={<TalkingAvtras customclass={true} />} />
//           </Route>
//         )}
//         <Route path="/upload-file" element={<UploadFile />} />
//         <Route path="/chatbot" element={<ChatingBot />} /> */}
//       {/* </Routes> */}
//     </div>
//   );
// }

export default App;
